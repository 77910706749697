/* AgeVerification.css */

.age-verification-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.8);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
  animation: fadeIn 0.5s ease;
}

@keyframes fadeIn {
  from { opacity: 0; }
  to { opacity: 1; }
}

.age-verification-card {
  background-color: #1f1f1f;
  padding: 2.5rem;
  border-radius: 12px;
  width: 90%;
  max-width: 400px;
  text-align: center;
  color: #f0f0f0;
  box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.3);
  transform: scale(1);
  transition: transform 0.3s ease;
}

.age-verification-card:hover {
  transform: scale(1.02); /* Slight zoom on hover */
}

.age-verification-card h1 {
  color: #23d17c;
  font-weight: 700;
  font-size: 1.8rem;
  margin-bottom: 1rem;
  letter-spacing: 1px;
}

.age-verification-card p {
  font-size: 1rem;
  color: #cccccc;
  margin-bottom: 1.5rem;
}

.age-form {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.age-inputs {
  display: flex;
  gap: 0.5rem;
}

.age-inputs input {
  width: 33%;
  padding: 0.5rem;
  font-size: 1rem;
  text-align: center;
  border: 1px solid #555;
  border-radius: 5px;
  background-color: #2b2b2b;
  color: #ffffff;
}

.age-inputs input::placeholder {
  color: #888;
}

.remember-me {
  display: flex;
  align-items: center;
  gap: 0.5rem;
  font-size: 0.9rem;
  color: #cccccc;
}

.remember-me input {
  accent-color: #23d17c; /* Green checkbox color */
}

.submit-button {
  background-color: #23d17c;
  color: #ffffff;
  padding: 0.6rem 1.2rem;
  font-size: 1rem;
  font-weight: bold;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.submit-button:hover {
  background-color: #1fa367; /* Darker green on hover */
}

.age-warning {
  font-size: 0.8rem;
  color: #aaaaaa;
  margin-top: 1rem;
  line-height: 1.2;
}

.error-message {
  color: #ff4d4d;
  font-size: 0.9rem;
  margin-top: 0.5rem;
}
