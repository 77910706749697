/* Homepage.css */

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@300;600&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Lobster&family=Montserrat:wght@400;600&display=swap');


body {
  font-family: 'Montserrat', sans-serif;
  background: radial-gradient(circle, #ffe7e7, #e7fffd);
  color: #333;
  overflow-x: hidden;
}

.homepage {
  display: flex;
  flex-direction: column;
  align-items: center;
  min-height: 100vh;
  color: #333;
  text-align: center;
  padding: 0 1rem;
  animation: fadeIn 1.2s ease;
}

.header {
  width: 100%;
  padding: 1rem 0;
  display: flex;
  justify-content: center;
  align-items: center;
  position: sticky;
  top: 0;
  z-index: 1000;
}

.social-icons {
  display: flex;
  gap: 1.2rem;
  margin-right: auto;
  padding-left: 1rem;
}

.icon-link img {
  width: 30px;
  height: 30px;
  filter: drop-shadow(0px 4px 6px rgba(0, 0, 0, 0.2));
  transition: transform 0.3s ease, filter 0.3s ease;
}

.icon-link:hover img {
  transform: scale(1.2);
  filter: drop-shadow(0px 6px 8px rgba(0, 0, 0, 0.4));
}

.header-logo {
  width: 80px;
  cursor: pointer;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.header-logo:hover {
  transform: scale(1.2);
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.3);
}

h1 {
  margin-top: 1rem;
  font-size: 2.8rem;
  font-weight: 700;
  color: #ff5a5f;
  letter-spacing: 1px;
  text-shadow: 1px 3px 5px rgba(0, 0, 0, 0.15);
  animation: fadeSlideIn 1.2s ease forwards;
  opacity: 0;
}

.highlight {
  color: #5f5fff;
  background: linear-gradient(45deg, #ff5a5f, #5f5fff);
  -webkit-background-clip: text;
  color: transparent;
  font-weight: bold;
}

.carousel {
  position: relative;
  width: 85%;
  max-width: 400px;
  height: 400px;
  overflow: hidden;
  margin-top: 2rem;
  border-radius: 20px;
  box-shadow: 0px 10px 25px rgba(0, 0, 0, 0.2);
}

.carousel-image {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 20px;
}

.carousel-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background: rgba(0, 0, 0, 0.4); /* Semi-transparent dark background */
  color: #fff;
  font-size: 1.5rem;
  font-weight: bold;
  cursor: pointer;
  border-radius: 20px;
  opacity: 1; /* Always visible */
}

.carousel-hint {
  animation: pulse 1.5s infinite; /* Adds pulsing effect */
}

@keyframes pulse {
  0%, 100% {
      opacity: 1;
      transform: scale(1);
  }
  50% {
      opacity: 0.7;
      transform: scale(1.1);
  }
}


.intro {
  margin: 2rem 0;
  font-size: 1.1rem;
  line-height: 1.6;
  max-width: 600px;
  color: #444;
  padding: 0 1rem;
  background: #fff3b0;
  border-radius: 12px;
  padding: 1.5rem;
  box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.1);
}

.features {
  text-align: center;
  margin-bottom: 2rem;
}

.features h2 {
  font-size: 2rem;
  margin-bottom: 1rem;
  color: #5f5fff;
  text-shadow: 0px 3px 6px rgba(0, 0, 0, 0.1);
}

.features ul {
  list-style: none;
  padding: 0;
  display: flex;
  flex-direction: column;
  gap: 0.8rem;
}

.features li {
  font-size: 1.2rem;
  color: #ff5a5f;
  transition: transform 0.2s ease;
}

.features li:hover {
  transform: scale(1.1);
}

.cta-button {
  padding: 0.8rem 2rem;
  font-size: 1.2rem;
  color: #fff;
  background: linear-gradient(45deg, #ff5a5f, #5f5fff);
  border: none;
  border-radius: 30px;
  cursor: pointer;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  margin-bottom: 2rem;
  box-shadow: 0px 5px 15px rgba(0, 0, 0, 0.3);
}

.cta-button:hover {
  transform: scale(1.05);
  box-shadow: 0px 8px 20px rgba(0, 0, 0, 0.5);
}

@keyframes fadeSlideIn {
  from {
      opacity: 0;
      transform: translateY(20px);
  }
  to {
      opacity: 1;
      transform: translateY(0);
  }
}

@keyframes scaleIn {
  from {
      opacity: 0;
      transform: scale(1.05);
  }
  to {
      opacity: 1;
      transform: scale(1);
  }
}


