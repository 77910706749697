.start-page {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    min-height: 100vh;
    padding: 2rem;
    background-color: #fff;
    color: #333;
    text-align: center;
    font-family: 'Montserrat', sans-serif;
  }
  
  .start-page h2 {
    font-size: 2rem;
    font-weight: bold;
    color: #4a4a4a;
    margin-bottom: 1rem;
  }
  
  .start-page p {
    font-size: 1.1rem;
    color: #666;
    margin-bottom: 2rem;
    max-width: 600px;
  }
  
  .start-page button {
    background-color: #000;
    color: #fff;
    padding: 0.8rem 1.5rem;
    font-size: 1rem;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s ease, transform 0.3s ease;
  }
  
  .start-page button:hover {
    background-color: #444;
    transform: scale(1.05);
  }
  