/* CookieConsent.css */
.cookie-consent {
  position: fixed;
  bottom: 1rem; /* Slightly raised from the bottom */
  left: 1rem; /* Aligned to the left with some padding */
  max-width: 300px; /* Set a fixed width for a compact look */
  background-color: rgba(34, 34, 34, 0.95); /* Slightly transparent dark background */
  color: #ffffff;
  padding: 1rem;
  border-radius: 8px; /* Rounded corners */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.3); /* Shadow for a floating effect */
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  z-index: 1000;
}

.cookie-consent p {
  margin: 0 0 0.5rem 0; /* Spacing below the text */
  font-size: 0.9rem; /* Slightly smaller font for compactness */
}

.cookie-consent button {
  background-color: #23d17c;
  border: none;
  border-radius: 4px;
  color: #ffffff;
  padding: 0.5rem 1.2rem;
  font-size: 0.85rem;
  cursor: pointer;
  transition: background-color 0.3s;
}

.cookie-consent button:hover {
  background-color: #1fa367;
}
